import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueSweetalert2 from 'vue-sweetalert2'
import router from './router/index'
import store from '@/state/store'
import vco from "v-click-outside"
import VueClipboard from 'vue-clipboard2'
import i18n from './i18n/index'
import "@/design/index.scss";

import Sparkline from 'vue-sparklines'

Vue.use(BootstrapVue);

Vue.config.productionTip = false
Vue.use(VueClipboard)
Vue.use(Vuelidate);
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(vco)
Vue.use(Sparkline)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
