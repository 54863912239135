import axios from 'axios'
import config from './config'
import router  from '@/router/index';
import store from '@/state/store'
import Swal from "sweetalert2";
export default function request(options){
    return new Promise((resolve,reject)=>{
        const instance = axios.create({...config})
        instance.interceptors.request.use(
            (config)=>{
                let token = localStorage.getItem("auth.token");
                if(token){
                    config.headers.Authorization = token;
                }
                return config;
            },
            (error)=>{
                if(error.code==="ECONNABORTED" && error.message.indexOf("timeout")!==-1){
                    Swal.fire("System Error", "请求超时", "error");
                }
                return Promise.reject(error);
            }
        );
        instance.interceptors.response.use(
            (response) => {
                return response.data;
            },
            (err) => {
                if(err && err.response){
                    switch(err.response.status){
                        case 400:
                            err.message="请求参数错误";
                            break;
                        case 401:
                            err.message="请登录";
                            break;
                        case 403:
                            err.message="未授权";
                            break;     
                        case 500:
                            err.message="服务器内部错误";
                            break;
                        case 501:
                            err.message="服务器未实现";
                            break;
                        case 502:
                            err.message="网关错误";
                            break; 
                        default:
                            break;                                                                                
                    }
                }
                if(err.message){
                    if(err.response.status==401){
                        store.commit('auth/CLEAR_TOKEN');
                        router.push("/login");
                    }else{
                        Swal.fire("System Error", err.message, "error");
                    }
                }
                return Promise.reject(err);
            }
        )
        instance(options).then((res)=>{
            if(res.code === 200){
                resolve(res);
            }else{
                const loginStatus = options.url.indexOf("/auth/")!=-1;
                if(loginStatus){
                    resolve(res);
                }else{
                    if(res.code===401){
                        console.info("code="+401);
                        store.commit('auth/CLEAR_TOKEN');
                        router.push("/login");
                    }else if(res.code===403){
                        router.push("/errors/403");
                    }else{
                        if(res.message.indexOf("ERR_")==0){
                            //指定错误提示逻辑放到业务模块
                        }else{
                            Swal.fire("System Error", res.msg||"操作失败", "error");
                        }
                    }                
                    reject(res);
                }
            }
        })
        .catch((error)=>{
            reject(error);
        });
    });
}