import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from './zh-cn'
import en from './en'
import vn from './vn'
Vue.use(VueI18n)
if(localStorage.getItem('language')==null){
    localStorage.setItem('language','zh-CN'!=navigator.language?"en":"zh-CN")
}
const i18n = new VueI18n({
    // 设置默认语言
    locale: localStorage.getItem('language') || ('zh-CN'!=navigator.language?"en":"zh-CN"), // 语言标识
    // 添加多语言（每一个语言标示对应一个语言文件）
    messages: {
      'zh-CN': zh,
      'en': en,
      'vn': vn
    }
  })
export default i18n
