import request from "@/request";
export const login = (data)=>{
    return request({
        url:"/auth/oauth/token",
        method:"post",
        headers: {
            Authorization: '',
        },
        params:data,
    })
}
export const logout = ()=>{
    return request({
        url: '/auth/oauth/logout',
        method: 'delete',
    });
}
export const getVerificationCode = (data)=>{
    return request({
        url:"/auth/oauth/smsvcode",
        method:"post",
        data,
    })
}